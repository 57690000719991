import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { FaChevronCircleUp } from "react-icons/fa";
import Layout from "../components/layout";

const Page = ({ data }) => {
  const page = data.markdownRemark;
  const [showTopButton, toggleShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      toggleShowTopButton(window.scrollY > 500 ? true : false);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [showTopButton]);

  return (
    <Layout>
      <article className="w-100 center bg-custom-beige pa5-l pv3-m ph3 pv2 mv5 mh4">
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
        {showTopButton ? (
          <FaChevronCircleUp
            onClick={() => scrollTo("#top")}
            className="top-button bottom-2 right-1 fixed f2 pointer z-999 custom-white"
          />
        ) : (
          ""
        )}
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { lang: { eq: $language } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default Page;
